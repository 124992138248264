import React from 'react'
import style from './Index.module.less'
import { Form, Empty } from 'antd'
import Text from '@/components/Text'
import { formatDate } from '@/assets/js/tool'
import FileList from '../FileList/Index'
import moment from 'moment'

const { Item, List } = Form

export function formatUpdateData (d) {

}

export function initFormData (data, result) {
  if (data.education_info instanceof Array) {
    result.education_info = data.education_info.map(li => {
      return {
        ...li,
        begin_datetime: typeof li.begin_datetime === 'string' ? Math.floor(moment(li.begin_datetime, 'YYYY-MM').valueOf() / 1000) : li.begin_datetime,
        end_datetime: typeof li.end_datetime === 'string' ? Math.floor(moment(li.end_datetime, 'YYYY-MM').valueOf() / 1000) : li.end_datetime
      }
    })
  }
}

function Main () {
  return (
    <div className={style.container}>
      <div className={style.title}>教育背景</div>
      <List name='education_info'>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <div className={style.line} key={key}>
                  <div>
                    <span className={style.time}>
                      <Item name={[name, 'begin_datetime']} noStyle {...restField}>
                        <Text format={v => formatDate(v, 'YYYY.MM')} />
                      </Item>
                      <span>～</span>
                      <Item name={[name, 'end_datetime']} noStyle {...restField}>
                        <Text format={v => v ? formatDate(v, 'YYYY.MM') : '至今'} />
                      </Item>
                    </span>
                    <span className={style.cell}>
                      <Item name={[name, 'university']} noStyle {...restField}>
                        <Text format={v => v || <span className={style.no}>无</span>} />
                      </Item>
                    </span>
                    <span className={style.cell}>
                      <Item name={[name, 'major']} noStyle {...restField}>
                        <Text format={v => v || <span className={style.no}>无</span>} />
                      </Item>
                    </span>
                    <span className={style.cell}>
                      <Item name={[name, 'educational']} noStyle {...restField}>
                        <Text format={v => v || <span className={style.no}>无</span>} />
                      </Item>
                    </span>
                    <Item name={[name, 'file']} noStyle>
                      <FileList />
                    </Item>
                  </div>
                </div>
              )
            })}
            {
              fields.length === 0 && (
                <div>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )
            }
          </>
        )}
      </List>
    </div>
  )
}

export default Main
