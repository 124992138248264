import React from 'react'
import style from './Index.module.less'
import { Form, Row, Col } from 'antd'
import Text from '@/components/Text'

const { Item } = Form

export function formatUpdateData (d) {
  // if (d.research_tag instanceof Array && d.research_tag.length > 0) {
  //   d.research_tag = d.research_tag.map(li => li.label).join(',')
  //   saveTags(d.research_tag).catch(e => console.error(e))
  // }
}

export function initFormData (data, result) {
  if (typeof data.research_tag === 'string' && data.research_tag.length > 0) {
    result.research_tag = data.research_tag
  }
}

function Main () {
  return (
    <div>
      <div className={style.title}>研究领域</div>
      <Row gutter={24}>
        <Col span={24}>
          <Item label='研究领域' name='research_tag'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
      </Row>
    </div>
  )
}

export default Main
