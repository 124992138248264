import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Popover } from 'antd'
import { CloseOutlined, DownOutlined, HomeFilled } from '@ant-design/icons'
import { setActiveWindowId, delWindow, addResizeEvent, delResizeEvent } from '@/redux/actions'
import style from './TabBar.module.less'

class TabBar extends Component {
  state = {
    hideTabsVisible: false,
    activeTabId: '1',
    showTabList: [],
    hideTabList: []
  }

  initShowAndHideList () {
    if (!this.content) {
      return false
    }
    const pageList = this.props.windows
    const list = pageList.map(page => {
      if (!page.closable) {
        return page.width + 24
      }
      return page.width + 48
    })
    const maxWidth = this.content.offsetWidth - 48
    let num = 0
    const showTabList = []
    const hideTabList = []
    list.forEach((li, i) => {
      if (num + li <= maxWidth) {
        num += li
        showTabList.push(pageList[i])
      } else {
        hideTabList.push(pageList[i])
      }
    })
    this.setState({
      showTabList,
      hideTabList
    })
  }

  /**
   * 隐藏菜单内是否包含当前展示菜单
   */
  IsHasCurrentPage () {
    return this.state.hideTabList.some(tab => tab.id === this.props.activeWindowId)
  }

  /**
   * 关闭隐藏菜单中的窗口
   */
  delPageInHide (id) {
    if (this.state.hideTabList.length === 1) {
      this.setState({
        hideTabsVisible: false
      })
    }
    this.props.delWindow(id)
  }

  /* 关闭窗口 */
  delWindow (e, id) {
    e.stopPropagation()
    this.props.delWindow(id)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.windows !== this.props.windows) {
      this.initShowAndHideList()
    }
  }

  componentDidMount () {
    this.props.addResizeEvent({ name: 'resizeAdminTab', handle: () => this.initShowAndHideList() })
    this.initShowAndHideList()
  }

  componentWillUnmount () {
    this.props.delResizeEvent('resizeAdminTab')
  }

  renderShowTabList () {
    return this.state.showTabList.map(item => {
      let className = `${style['tab-bar__tab']}`
      if (item.id === this.props.activeWindowId) {
        className += ` ${style.active}`
      }
      if (!item.closable) {
        className += ` ${style.static}`
      }
      return (
        <div className={className} key={item.id} onClick={() => this.props.setActiveWindowId(item.id)}>
          {item.isHome ? <HomeFilled className={style['is-home']} /> : null}
          <span className={style['tab-bar__tab__title']}>{item.title}</span>
          {item.closable ? <CloseOutlined className={style['tab-bar__tab__icon']} onClick={(e) => this.delWindow(e, item.id)} /> : null}
        </div>
      )
    })
  }

  renderHideTab () {
    let className = `${style['tab-bar__more-btn']}`
    if (this.state.hideTabsVisible) {
      className += ` ${style.active}`
    }
    if (this.IsHasCurrentPage()) {
      className += ` ${style.hasCurrentPage}`
    }
    if (!this.state.hideTabList.length) {
      return null
    }
    const content = (
      <div className={style['hide-tabs']}>
        <ul>
          {this.state.hideTabList.map(item => {
            const className = item.id === this.props.activeWindowId ? style.active : ''
            return (
              <li key={item.id} className={className} onClick={() => this.props.setActiveWindowId(item.id)}>
                <div className={style['hide-tab__title']}>{item.title}</div>
                <div className={style['hide-tab__close-btn']}>
                  <CloseOutlined className={style['hide-table__close-btn__icon']} onClick={e => this.delWindow(e, item.id)} />
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    )
    return (
      <Popover title='其他页面' trigger='click' content={content} onVisibleChange={v => this.setState({ hideTabsVisible: v })}>
        <div
          className={className} onClick={() => this.setState({
            hideTabsVisible: !this.state.hideTabsVisible
          })}
        >
          <div className={style['tab-bar__more-btn__inner']}>
            <DownOutlined />
          </div>
        </div>
      </Popover>
    )
  }

  render () {
    return (
      <div className={style['tab-bar']} ref={ref => { this.content = ref }}>
        {this.renderShowTabList()}
        {this.renderHideTab()}
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    windows: state.pageMeta.windows,
    activeWindowId: state.pageMeta.activeWindowId
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setActiveWindowId: i => dispatch(setActiveWindowId(i)),
    delWindow: i => dispatch(delWindow(i)),
    addResizeEvent: i => dispatch(addResizeEvent(i)),
    delResizeEvent: i => dispatch(delResizeEvent(i))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabBar)
