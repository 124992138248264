export const caseStatusList = ['收案', '交费', '立案', '移交送达', '送达完成', '移交办案', '已组庭', '已结案', '已结案送达', '已归档']
export const modifyStatusList = ['已提交', '收案', '交费', '立案', '移交送达', '送达完成', '移交办案', '已组庭', '已结案', '已结案送达', '已归档']
export const acceptStatusList = ['已提交', '收案', '交费'] // 收案的状态
export const doingStatusList = ['立案', '移交送达', '送达完成', '移交办案', '已组庭'] // 办理中的状态
export const beforeClosedStatusList = [...acceptStatusList, ...doingStatusList] // 结案前的案件状态
export const normalStatusList = ['立案', '移交送达', '送达完成', '移交办案', '已组庭', '已结案', '已结案送达', '已归档'] // 立案之后的状态
export const paiedStatusList = ['交费', '立案', '移交送达', '送达完成', '移交办案', '已组庭', '已结案', '已结案送达'] // 交费之后的案件状态
export const caseClosedStatusList = ['已结案', '已结案送达', '已归档']
export const companyName = '厦门仲裁委员会'
export const companyUrl = 'www.xmac.org.cn'
export const appName = '厦仲信息化管理平台'
export const arbitratorOccupation = ['律师', '企业', '公务员', '退休法官', '教学研究']
export const colors = ['#3b98ff', '#29ba51', '#f9c514', '#ee3f59', '#7d3fdc', '#1f2c68', '#2e2fc0', '#14baba', '#F56B00', '#009825', '#F0F2F5']
export const defaultProcedure = '非简易程序'
export const simpleProcedure = '简易程序'

// 多个邮箱正则
export const emailPattern = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+(,[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+)*$/

export const politicalList = ['中共党员', '中共预备党员', '共青团员', '民革党员', '民盟盟员', '民建会员', '民进会员', '农工党党员', '致公党党员', '九三学社社员', '台盟盟员', '无党派人士', '群众']

export const clanList = ['汉族', '壮族', '蒙古族', '回族', '藏族', '维吾尔族', '苗族', '彝族', '布依族', '朝鲜族', '满族', '侗族', '瑶族', '白族', '东乡族', '锡伯族', '土家族', '哈尼族', '哈萨克族', '傣族', '黎族', '僳僳族', '佤族', '畲族', '拉祜族', '水族', '纳西族', '景颇族', '柯尔克孜族', '土族', '高山族', '达斡尔族', '仫佬族', '羌族', '撒拉族', '德昂族', '仡佬族', '阿昌族', '普米族', '布朗族', '塔吉克族', '怒族', '乌孜别克族', '俄罗斯族', '鄂温克族', '毛南族', '保安族', '裕固族', '京族', '塔塔尔族', '独龙族', '鄂伦春族', '赫哲族', '门巴族', '珞巴族', '基诺族']

// export const xcxUrl = 'https://xaarbitrator.zhichu.tech'
export const xcxUrl = 'https://arbitrator.xmac.org.cn'

export const pk = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1IktOwr+4VSXGUmYfaqsfe1dT
VPKOW/LYQUHF7VBB75Pa5gMl+wI9CW2Y7th9F/9MFokQINzoZg1bsR157XOdO8bE
Vr00HBpFCtyHxw9jqWoaivDJlxsmYXdC24X46mWg8/E6LWGOXx98cTQ3k/K67NwD
r6JaRBJp1tkVcO4pkwIDAQAB
-----END PUBLIC KEY-----`
