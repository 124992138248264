export default class Main {
  list = []
  idMap = new Map()
  currentList = []

  constructor (trees) {
    this.list = this.formatListFromTree(trees)
  }

  formatListFromTree (trees) {
    const list = []
    let stack = [...trees]
    while (stack.length) {
      const o = stack.shift()
      if (o.title.indexOf('大项') !== -1) {
        o.type = 0
      } else if (o.title.indexOf('小项') !== -1) {
        o.type = 1
      } else {
        o.type = 2
      }
      list.push(o)
      this.idMap.set(o.id, o)
      if (o.children instanceof Array && o.children.length > 0) {
        stack = stack.concat(o.children)
      }
    }
    return list
  }

  initIdsToTreeList (ids) {
    if (typeof ids !== 'string') {
      return undefined
    }
    ids = ids.split(',')
    const l = []
    const m = new Map()
    ids.forEach(id => {
      const o = this.idMap.get(id)
      if (o) {
        const no = { ...o, key: o.title, children: [] }
        l.push(no)
        m.set(no.id, no)
      }
    })
    l.forEach(li => {
      if (li.pid) {
        const p = m.get(li.pid)
        if (p) {
          p.children.push(li)
        }
      }
    })
    this.currentList = l.filter(li => li.type === 0)
  }

  getValidateError () {
    // const l = this.currentList
    // if (l.length > 3) {
    //   return '专业大项最多可选三项'
    // }
    // for (let i = 0; i < l.length; i++) {
    //   const o = l[i]
    //   const fo = this.idMap.get(o.id)
    //   if (o.children.length < 3 && fo.children.length !== o.children.length) {
    //     return `${o.title}下的专业小项少于三个`
    //   }
    // }
    return ''
  }
}
