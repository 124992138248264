// 送达案件管理
import React from 'react'
import LazyLoad from '../../LazyLoad'

const ClassOnlineList = (props) => <LazyLoad loadFn={() => import('../../../pages/trainManage/ClassOnlineList/Index')} {...props} />
const ClassOnlineDetail = (props) => <LazyLoad loadFn={() => import('../../../pages/trainManage/ClassOnlineDetail/Index')} {...props} />
const VideoDetail = (props) => <LazyLoad loadFn={() => import('../../../pages/trainManage/VideoDetail/Index')} {...props} />

const router = {
  ClassOnlineList, // 线上课程管理
  ClassOnlineDetail, // 线上课程详情
  VideoDetail // 视频播放详情
}

export default router
