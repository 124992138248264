import { setToken, clearWindow, addWindow, delWindow } from '@/redux/actions'
import { message } from 'antd'
import { awaitWrap } from './tool'
import { $get, $post } from './authRequest'

// 获取文书列表请求
async function getFileListRequest (caseid) {
  const res = await $get(`/api/xapc/case/file/${caseid}`, {
    params: {
      current: 1,
      page_size: 1000
    }
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 创建文书
async function createDoc (data) {
  const res = await $post('/api/xapc/case/file', data)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

export default class Common {
  static store = null
  _fileList = [] // 已有文书列表
  _fileListLoaded = false
  _fileCreating = false
  _printing = false // 打印中

  setToken (i) {
    Common.store.dispatch(setToken(i))
  }

  clearWindow (i) {
    Common.store.dispatch(clearWindow(i))
  }

  addWindow (i) {
    Common.store.dispatch(addWindow(i))
  }

  delWindow (i) {
    Common.store.dispatch(delWindow(i))
  }

  // 获取案件文书列表
  async $getFileList (caseid) {
    this._fileListLoaded = true
    const [e, d] = await awaitWrap(getFileListRequest(caseid))
    this._fileListLoaded = false
    if (e) {
      return message.error(e.message)
    }
    this._fileList = d.list
    return {
      _fileList: d.list,
      _fileListLoaded: true
    }
  }

  // 生成指定文书
  async $createDoc (caseid, fileNames = [], bizid = '', notice = '') {
    const hide = message.loading(notice || '文书生成中', 120)
    this._fileCreating = true
    const data = {
      caseid,
      filename: fileNames
    }
    if (bizid) {
      data.bizid = bizid
    }
    const [e, d] = await awaitWrap(createDoc(data))
    this._fileCreating = false
    if (e) {
      hide()
      return message.error(e.message)
    }
    const [err] = await awaitWrap(this.$getFileList(caseid))
    hide()
    if (err) {
      return message.error(err.message)
    }
    return d
  }

  /**
   * 打开菜单
   */
  $openPage ({ id = '', title = '', componentName = '', closable = true, meta = {}, isHome = false }) {
    this.addWindow({ id, title, componentName, closable, meta, isHome })
  }

  // 查看pdf文书
  $readerPdf (filename, id, caseid, readonly = false) {
    this.$openPage({ id: `${filename}-${id}`, title: `${filename}`, componentName: 'PdfReader', meta: { caseid: caseid, fileid: id, readonly } })
  }

  // 查看指定名称的文书
  $readerFileByName (filename, caseid) {
    const list = this._fileList
    let id = ''
    for (let i = 0; i < list.length; i++) {
      if (list[i].title.indexOf(filename) !== -1) {
        id = list[i].id
        this.$readerPdf(list[i].title, id, caseid)
        break
      }
    }
    if (!id) {
      message.warning('文书不存在！')
    }
  }

  // 查看文书id的文书
  $readerFileById (fileid, caseid) {
    const list = this._fileList || []
    let index = -1
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === fileid) {
        index = i
        this.$readerPdf(list[i].title, fileid, caseid)
        break
      }
    }
    if (index === -1) {
      message.warning('文书不存在！')
    }
  }

  // 打印文档
  $print (filepath) {
    this._printing = true
    const oldIframe = document.getElementById('print')
    if (oldIframe) {
      document.body.removeChild(oldIframe)
    }
    const hide = message.loading('文件加载中，准备打印', 120)
    const iframe = document.createElement('iframe')
    iframe.setAttribute('id', 'print')
    iframe.setAttribute('style', 'width: 0; height: 0; position: absolute; top: -500px; left: -500px;')
    iframe.setAttribute('src', filepath)
    iframe.onload = () => {
      hide()
      this._printing = false
      iframe.contentWindow.print()
    }
    document.body.appendChild(iframe)
  }
}
