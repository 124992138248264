import React from 'react'
import { useHistory } from 'react-router-dom'
import style from './Index.module.less'
import { companyName, appName } from '../../assets/js/config'
import Sms from './components/Sms/Index'

function Main () {
  const h = useHistory()
  return (
    <div className={style.container}>
      <div className={style.header}>
        <a onClick={() => { h.goBack() }}>
          <img src={require('../../assets/img/logo.png')} alt='logo' />
          <span>{companyName}</span>
          <span className={style['login-content__header__point']} />
          <span>{appName}</span>
        </a>
        <a className={style.back} onClick={() => { h.goBack() }}>返回</a>
      </div>
      <div className={style.inner}>
        <div className={style['left-container']}>
          <ul className={style['left-list']}>
            <li className={style.active}>短信验证</li>
          </ul>
        </div>
        <div className={style['right-container']}>
          <Sms />
        </div>
      </div>
    </div>
  )
}

export default Main
