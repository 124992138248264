import axios from 'axios'
import { message } from 'antd'
import { $post as vPost } from './authRequest'
const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  },
  timeout: 1000 * 120
})
instance.interceptors.request.use(function (config) {
  // config.url = `/api${config.url}`;
  return config
})
/**
 * 请求方法
 * @param {*} type 请求类型
 */
function request (type) {
  return function (url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      instance[type](url, params, config)
        .then(res => {
          resolve(res.data)
        })
        .catch(e => {
          const { status, data } = e.response || { status: 504 }
          if (status === 504) {
            message.error('网络异常', 2)
          } else if (typeof data.message === 'string') {
            message.error(data.message, 2).then(null, null)
          } else if (status >= 400 && status < 500) {
            message.error('系统网络异常，请联系网络管理员', 2).then(null, null)
          } else {
            message.error('服务器异常，请联系管理员', 2).then(null, null)
          }
          reject(e)
        })
    })
  }
}

/**
   * 上传多个文件，并返回文件拼接的id
   */
export async function submitFiles (files) {
  const { exitFileIds, newFiles } = getDistinctFiles(files)
  const list = newFiles.map(requestOneFile)
  const resList = await Promise.all(list)
  const result = exitFileIds
  resList.forEach(res => {
    if (res.status === 200) {
      result.push(res.data.data.id)
    }
  })
  return result.join()
}

/**
   * 上传多个文件，并返回文件拼接的id
   */
export async function submitFilesAndReturnFiles (files) {
  const { exitFileIds, newFiles } = getDistinctFiles(files)
  const list = newFiles.map(requestOneFile)
  const resList = await Promise.all(list)
  const result = exitFileIds
  resList.forEach(res => {
    if (res.status === 200) {
      result.push(res.data.data)
    }
  })
  return result
}

/**
   * 将文件分为已上传文件和未上传文件
   */
export function getDistinctFiles (files) {
  const exitFileIds = []
  const newFiles = []
  files.forEach(file => {
    if (file instanceof File) {
      newFiles.push(file)
    } else if (file.originFileObj instanceof File) {
      newFiles.push(file.originFileObj)
    } else {
      exitFileIds.push(file.id)
    }
  })
  return { exitFileIds, newFiles }
}

/**
   * 提交单个文件
   * @param {*} file 文件对象
   */
export function requestOneFile (file, setProgress = () => {}) {
  const formData = new FormData()
  if (file instanceof File) {
    formData.append('upload_file', file)
  } else if (file.originFileObj instanceof File) {
    formData.append('upload_file', file.originFileObj)
  }
  return vPost('/api/common/vupload', formData, { 'Content-Type': 'multipart/form-data', onUploadProgress: (v) => { setProgress(((v.loaded / file.size) * 100).toFixed(2)) } }).then(res => { if (res.status !== 1) { throw new Error(res.message) } return { status: 200, data: res } })
}

/**
 * 上传多个文件,并返回这些文件拼接的数组
 * @param {*}} files 多个文件对象
 */
export async function requestFiles (files) {
  const list = files.map(requestOneFile)
  const resList = await Promise.all(list)
  const result = []
  resList.forEach(res => {
    if (res.status === 200 && res.data.status === 1) {
      result.push(res.data.data)
    } else {
      throw new Error('上传文件失败')
    }
  })
  return result
}

export default instance

export const $get = request('get')
export const $post = request('post')
export const $put = request('put')
export const $delete = request('delete')
