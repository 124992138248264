// 后台管理
import React from 'react'
import LazyLoad from '../../LazyLoad'

const IDockingLogList = (props) => <LazyLoad loadFn={() => import('../../../pages/courtManage/IDockingLogList/Index')} {...props} />
const IDockingLogCaseList = (props) => <LazyLoad loadFn={() => import('../../../pages/courtManage/IDockingLogCaseList/Index')} {...props} />
const IDockingLogStat = (props) => <LazyLoad loadFn={() => import('../../../pages/courtManage/IDockingLogStat/Index')} {...props} />
const IDockingCaseExcution = (props) => <LazyLoad loadFn={() => import('../../../pages/courtManage/IDockingCaseExcution/Index')} {...props} />
const IDockingNFClosureDoc = (props) => <LazyLoad loadFn={() => import('../../../pages/courtManage/IDockingNFClosureDoc/Index')} {...props} />
const IDockingMyNFClosureDoc = (props) => <LazyLoad loadFn={() => import('../../../pages/courtManage/IDockingMyNFClosureDoc/Index')} {...props} />
const YSupremeCourtStat = (props) => <LazyLoad loadFn={() => import('../../../pages/courtManage/YSupremeCourtStat/Index')} {...props} />
const YSupremeCourtCaseList = (props) => <LazyLoad loadFn={() => import('../../../pages/courtManage/YSupremeCourtCaseList/Index')} {...props} />
const YSupremeCourtCaseExecution = (props) => <LazyLoad loadFn={() => import('../../../pages/courtManage/YSupremeCourtCaseExecution/Index')} {...props} />
const YSupremeCourtCasePreservation = (props) => <LazyLoad loadFn={() => import('../../../pages/courtManage/YSupremeCourtCasePreservation/Index')} {...props} />

const router = {
  IDockingLogList, // 中院对接明细查询
  IDockingLogStat, // 中院对接统计
  IDockingCaseExcution, // 案件执行情况
  IDockingNFClosureDoc, // 结案文书
  IDockingMyNFClosureDoc, // 我的结案文书
  IDockingLogCaseList, // 中院对接案件汇总查询
  YSupremeCourtStat, // 国商庭案件情况
  YSupremeCourtCaseList, // 国商庭案件列表
  YSupremeCourtCaseExecution, // 国商庭撤销/执行案件
  YSupremeCourtCasePreservation // 国商庭保全案件
}

export default router
