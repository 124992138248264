import React from 'react'

import {
  Route,
  Router,
  Switch,
  withRouter
} from 'react-router-dom'

import './Router.less'
import { RouterConfig } from './RouteConfig'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { createBrowserHistory } from 'history'

const ANIMATION_MAP = {
  PUSH: 'forward',
  POP: 'back',
  REPLACE: 'back'
}

const Routes = withRouter(({ location, history }) => {
  return (
    <TransitionGroup
      className='router-wrapper'
      childFactory={child => React.cloneElement(
        child,
        { classNames: ANIMATION_MAP[history.action] }
      )}
    >
      <CSSTransition
        timeout={500}
        key={location.pathname}
      >
        <Switch location={location}>
          {RouterConfig.map((config, index) => (
            <Route exact key={index} {...config} />
          ))}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
})

export const history = createBrowserHistory()

export default class R extends React.PureComponent {
  render () {
    return (
      <Router history={history}>
        <Routes />
      </Router>
    )
  }
}
