// 送达案件管理
import React from 'react'
import LazyLoad from '../../LazyLoad'

const SendTransfer = (props) => <LazyLoad loadFn={() => import('../../../pages/sendCaseManage/SendTransfer')} {...props} />
const SendCaseManage = (props) => <LazyLoad loadFn={() => import('../../../pages/sendCaseManage/SendCaseManage')} {...props} />
const UnreceiveCaseManage = (props) => <LazyLoad loadFn={() => import('../../../pages/sendCaseManage/UnreceiveCaseManage')} {...props} />

const router = {
  SendCaseManage, // 送达案件
  SendTransfer, // 送达人员已移交案件
  UnreceiveCaseManage // 送达待接收案件
}

export default router
