// 后台管理
import React from 'react'
import LazyLoad from '../../LazyLoad'

const RoleAuthorities = (props) => <LazyLoad loadFn={() => import('../../../pages/adminManage/RoleAuthorities')} {...props} />
const DepartmentEmployee = (props) => <LazyLoad loadFn={() => import('../../../pages/adminManage/DepartmentEmployee')} {...props} />
const DocumentManage = (props) => <LazyLoad loadFn={() => import('../../../pages/adminManage/DocumentManage')} {...props} />
const LetterManage = (props) => <LazyLoad loadFn={() => import('../../../pages/adminManage/LetterManage')} {...props} />
const SystemLog = (props) => <LazyLoad loadFn={() => import('../../../pages/adminManage/SystemLog/Index')} {...props} />
const ResearchKnm = (props) => <LazyLoad loadFn={() => import('../../../pages/adminManage/ResearchKnm/Index')} {...props} />
const ArbitratorOpenInfoConfig = (props) => <LazyLoad loadFn={() => import('../../../pages/adminManage/ArbitratorOpenInfoConfig/Index')} {...props} />
const DsmArbitratorProfessionalTag = (props) => <LazyLoad loadFn={() => import('../../../pages/adminManage/DsmArbitratorProfessionalTag/Index')} {...props} />
const DsmArbitratorBankinfo = (props) => <LazyLoad loadFn={() => import('../../../pages/adminManage/DsmArbitratorBankinfo/Index')} {...props} />
const RequestLog = (props) => <LazyLoad loadFn={() => import('../../../pages/adminManage/RequestLog/Index')} {...props} />
const AccountMgt = (props) => <LazyLoad loadFn={() => import('../../../pages/adminManage/AccountMgt/Index')} {...props} />

const router = {
  RoleAuthorities, // 角色权限管理
  DepartmentEmployee, // 部门人员管理
  DocumentManage, // 文书管理
  LetterManage, // 信访管理
  SystemLog, // 系统日志
  ResearchKnm, // 研究领域管理
  ArbitratorOpenInfoConfig, // 仲裁员公开信息配置
  DsmArbitratorProfessionalTag, // 仲裁员擅长专业
  DsmArbitratorBankinfo, // 仲裁员账户信息
  RequestLog, // 操作日志
  AccountMgt // 系统账号管理
}

export default router
