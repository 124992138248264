import React from 'react'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import { ConfigProvider, message } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import { initMenuList, setMenuKeyTile } from '../redux/actions'
import style from './Admin.module.less'
import Sider from '../components/Admin/Sider'
import Header from '../components/Admin/Header'
import Footer from '../components/Admin/Footer'
import PageList from '../components/Admin/PageList'
class Admin extends Component {
  constructor (props) {
    super(props)
    if (!props.token) {
      document.documentElement.scrollTop = 0
      props.history.push('/login')
      message.warning('请先登录')
    }
  }

  state = {
    menus: []
  }

  scrollHandle (e) {
    this.props.scrollList.forEach(({ handle }) => {
      handle(e)
    })
  }

  resizeHandle (e) {
    this.props.resizeList.forEach(({ handle }) => {
      handle(e)
    })
  }

  // 格式化菜单
  formatMenu (data = {}) {
    const keys = Object.keys(data)
    const list = []
    const keyTileMap = {}
    keys.forEach(key => {
      const o = data[key]
      if (o.children) {
        const l = []
        const ks = Object.keys(o.children)
        ks.forEach(k => {
          const c = o.children[k]
          l.push({
            id: c.url,
            title: c.name,
            componentName: c.url,
            icon: c.ico,
            showChildren: true,
            children: c.children ? c.children : undefined
          })
          keyTileMap[c.url] = c.name
        })
        o.children = l
      }
      list.push({
        id: o.id,
        title: o.name,
        componentName: o.url,
        icon: o.ico,
        showChildren: true,
        children: o.children
      })
    })
    this.props.setMenuKeyTile(keyTileMap)
    return list
  }

  // 获取菜单数据
  getMenuData () {
    const { role_default: role } = this.props.userInfo || {}
    if (!role) {
      return false
    }
    const hide = message.loading('获取菜单中', 120)
    this.$get(`/api/admin/get_role_menu/${role}`, {
      params: {
        appid: '111'
      }
    }).then(res => {
      hide()
      if (res.status === 1) {
        const menus = this.formatMenu(res.data)
        this.setState({
          menus
        }, () => {
          this.props.initMenuList(menus)
        })
      } else {
        message.warning(res.message)
      }
    }).catch(() => hide())
  }

  componentDidMount () {
    this.getMenuData()
    window.addEventListener('scroll', this.scrollHandle.bind(this))
    window.addEventListener('resize', this.resizeHandle.bind(this))
  }

  render () {
    return (
      <ConfigProvider locale={zhCN}>
        <div className={style.admin}>
          <div className={style.admin__sider}>
            <Sider />
          </div>
          <div className={style.admin__content}>
            <Header history={this.props.history} />
            <div className={style.admin__page}>
              <PageList history={this.props.history} />
            </div>
            <Footer />
          </div>
        </div>
      </ConfigProvider>
    )
  }
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo,
    resizeList: state.globalEvent.resizeList,
    scrollList: state.globalEvent.scrollList,
    token: state.basic.token
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {
    initMenuList: (i) => dispatch(initMenuList(i)),
    setMenuKeyTile: (i) => dispatch(setMenuKeyTile(i))
  }
})

export default connect(mapState, mapDispatch)(Admin)
