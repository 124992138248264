import React, { Component } from 'react'
import style from './Footer.module.less'
import { companyName, companyUrl } from '@/assets/js/config'

export default class Footer extends Component {
  render () {
    return (
      <div className={style.footer}>
        {/* <div className={style.footer__nav}>
          <ul>
            <li><a href='#1'>帮助</a></li>
            <li><a href='#2'>隐私</a></li>
            <li><a href='#3'>条款</a></li>
          </ul>
        </div> */}
        <div className={style['footer-power']}>
          <span>{companyName}  {companyUrl}</span>
        </div>
      </div>
    )
  }
}
