// 立案管理
import React from 'react'
import LazyLoad from '../../LazyLoad'

// const AcceptCase = (props) => <LazyLoad loadFn={() => import('../../../pages/filingCaseManage/AcceptCase')} {...props} />
const AcceptCase = (props) => <LazyLoad loadFn={() => import('../../../pages/filingCaseManage/AcceptCase/Index')} {...props} />
const PreAcceptCase = (props) => <LazyLoad loadFn={() => import('../../../pages/filingCaseManage/AcceptCase/Pre')} {...props} />
const AcceptCaseManage = (props) => <LazyLoad loadFn={() => import('../../../pages/filingCaseManage/AcceptCaseManage')} {...props} />
const RegistryCaseManage = (props) => <LazyLoad loadFn={() => import('../../../pages/filingCaseManage/RegistryCaseManage')} {...props} />
const AcceptTransfer = (props) => <LazyLoad loadFn={() => import('../../../pages/filingCaseManage/AcceptTransfer')} {...props} />
const AuditList = (props) => <LazyLoad loadFn={() => import('../../../pages/filingCaseManage/AuditList')} {...props} />
const AuditListHs = (props) => <LazyLoad loadFn={() => import('../../../pages/filingCaseManage/AuditListHs')} {...props} />
const YSupremeCourtDockingAuditList = (props) => <LazyLoad loadFn={() => import('../../../pages/filingCaseManage/YSupremeCourtDockingAuditList')} {...props} />
const SecretaryExtendTime = (props) => <LazyLoad loadFn={() => import('../../../pages/filingCaseManage/SecretaryExtendTime/Index')} {...props} />
const LazyExtendTime = (props) => <LazyLoad loadFn={() => import('../../../pages/filingCaseManage/LazyExtendTime/Index')} {...props} />

const router = {
  AcceptCase, // 收案
  PreAcceptCase, // 预收案
  AcceptCaseManage, // 收案管理
  RegistryCaseManage, // 立案管理
  AcceptTransfer, // 立案人员已移交案件
  AuditList, // 待审核列表
  AuditListHs, // 海丝待审核列表
  SecretaryExtendTime, // 秘书超期延长审限列表
  LazyExtendTime, // 秘书超期延长审限案件列表
  YSupremeCourtDockingAuditList // 待审核（国商庭）
}

export default router
