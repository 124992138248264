import React, { Component } from 'react'
import { connect } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import style from './PageList.module.less'
import pageRouter from './pageRouter/index'
// import ErrorBoundary from '../ErrorBoundary'
class PageList extends Component {
  renderLoading () {
    return (
      <div className={style['loading-box']}>
        <LoadingOutlined />
      </div>
    )
  }

  render () {
    return (
      <div className={style.container}>
        {
          this.props.windows.map(p => {
            const Page = pageRouter[p.componentName]
            const styles = {}
            if (p.id !== this.props.activeWindowId) {
              styles.display = 'none'
            }
            return (
              <div className={style.page} key={p.key} style={styles}>
                {Page ? <Page {...p} history={this.props.history} /> : <span>模块不存在</span>}
                {/* {Page ? (
                  <ErrorBoundary>
                    <React.Suspense fallback={this.renderLoading()}>
                      <Page {...p} history={this.props.history} />
                    </React.Suspense>
                  </ErrorBoundary>
                ) : <span>模块不存在</span>} */}
              </div>
            )
          })
        }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    windows: state.pageMeta.windows,
    activeWindowId: state.pageMeta.activeWindowId
  }
}

function mapDispatchToProps (dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageList)
