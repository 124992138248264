import React from 'react'
import style from './Index.module.less'
import { Form, Empty } from 'antd'
import { formatDate } from '@/assets/js/tool'
import FileList from '../FileList/Index'

const { Item, List } = Form

const m = {
  qualification01: '从事仲裁工作满8年',
  qualification02: '从事律师工作满8年',
  qualification03: '曾任审判员满8年',
  qualification04: '从事法律研究、教学工作具有高级职称',
  qualification05: '具有法律知识、从事经济贸易专业工作并具有高级职称或具有同等专业水平'
}

export async function formatUpdateData (d) {

}

export function initFormData (data, result, fileSet) {
  if (data.qualification_info instanceof Array) {
    result.qualification_info = data.qualification_info
    data.qualification_info.forEach((li, i) => {
      result[li.qualification] = { ...li }
      if (result[li.qualification].file) {
        const k = parseInt(li.qualification[li.qualification.length - 1]) - 1
        fileSet[k](result[li.qualification].file)
      }
    })
  }
}

function Component ({ value, index }) {
  if (!value) {
    return <span />
  }
  const label = m[value.qualification] || ''

  return (
    <div className={style.line}>
      <div>
        {
          ['qualification01', 'qualification02', 'qualification03'].includes(value.qualification) ? (
            <span className={style.time}>
              {
                typeof value.begin_datetime === 'number' ? formatDate(value.begin_datetime, 'YYYY.MM') : '无'
              }
              <span>~</span>
              {
                typeof value.end_datetime === 'number' ? formatDate(value.end_datetime, 'YYYY.MM') : '至今'
              }
            </span>
          ) : (
            <span className={style.time}>
              {
                typeof value.begin_datetime === 'number' ? formatDate(value.begin_datetime, 'YYYY.MM') : '无'
              }
            </span>
          )
        }
        <span className={style.cell}>
          {label}
        </span>
        <span className={style.cell}>
          {value.structure || <span className={style.no}>无</span>}
        </span>

        <FileList value={(value.file && value.file instanceof Array ? value.file : []).filter(li => li.url).map(li => {
          const u = new URL(li.url)
          li.path = u.pathname
          return li
        })}
        />
      </div>
    </div>
  )
}

function Main ({ formRef, fileList1, fileList2, fileList3, fileList4, fileList5, setfileList1, setfileList2, setfileList3, setfileList4, setfileList5 }) {
  return (
    <div className={style.container}>
      <div className={style.title}>仲裁员任职资格</div>
      <List name='qualification_info'>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <div key={key}>
                  <Item name={[name]} noStyle>
                    <Component index={name + 1} />
                  </Item>
                </div>
              )
            })}
            {
              fields.length === 0 && (
                <div>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )
            }
          </>
        )}
      </List>
    </div>
  )
}

export default Main
