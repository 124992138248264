import { $get } from '@/assets/js/authRequest'
import { message } from 'antd'

export async function getTreeRequest () {
  const res = await $get('/api/xapc/professionaltag')
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}
