import * as T from './actionTypes'

// 设置基本信息
export const setUserInfo = (data) => ({ type: T.SET_USERINFO, data }) // 设置用户信息
export const setToken = (data) => ({ type: T.SET_TOKEN, data }) // 设置token
export const setSendTime = (data) => ({ type: T.SET_SEND_TIME, data }) // 设置发送时间
export const setLoginMobile = (data) => ({ type: T.SET_LOGIN_MOBILE, data }) // 设置发送短信的手机号
export const updateUncompleteMessage = (dispatch) => ({ type: T.UPDATE_UNCOMPLETE_MESSAGE, dispatch }) // 更新未读消息列表
export const setUncompleteMessage = (data) => ({ type: T.SET_UNCOMPLETE_MESSAGE, data }) // 设置未读消息列表
export const setUncompleteMessageNum = (data) => ({ type: T.SET_UNCOMPLETE_MESSAGE_NUM, data }) // 设置未读消息数量
export const setMenuKeyTile = (data) => ({ type: T.SET_MENU_KEY_TITLE, data }) // 设置菜单key和title映射

// tab页面相关
export const addWindow = (data) => ({ type: T.ADD_WINDOW, data }) // 新增窗口
export const delWindow = (data) => ({ type: T.DEL_WINDOW, data }) // 关闭窗口
export const modifyWindowMeta = (data) => ({ type: T.MODIFY_WINDOW_META, data }) // 修改窗口元数据
export const setActiveWindowId = (data) => ({ type: T.SET_ACTIVE_WINDOW_ID, data }) // 设置当前窗
export const initMenuList = (data) => ({ type: T.INIT_MENU_LIST, data }) // 初始化菜单列表
export const modifyWindowId = (data) => ({ type: T.MODIFY_WINDOW_ID, data }) // 修改窗口id
export const clearWindow = (data) => ({ type: T.CLEARWINDOW, data }) // 清空窗口

// 全局事件相关
export const addScrollEvent = (data) => ({ type: T.ADD_SCROLL_EVENT, data }) // 添加滚动事件处理函数
export const delScrollEvent = (data) => ({ type: T.DEL_SCROLL_EVENT, data }) // 移除滚动事件处理函数
export const clearScrollEvent = (data) => ({ type: T.CLEAR_SCROLL_EVENT, data }) // 清空滚动事件处理函数

export const addResizeEvent = (data) => ({ type: T.ADD_RESIZE_EVENT, data }) // 添加窗口大小改变事件处理函数
export const delResizeEvent = (data) => ({ type: T.DEL_RESIZE_EVENT, data }) // 移除窗口大小改变事件处理函数
export const clearResizeEvent = (data) => ({ type: T.CLEAR_RESIZE_EVENT, data }) // 清空窗口大小改变事件处理函数
