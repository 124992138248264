import React from 'react'
import style from './Index.module.less'
import { Form, Row, Col } from 'antd'
import Text from '@/components/Text'

const { Item } = Form

export function formatUpdateData (d) {
  if (!d.caseno_preyear && d.caseno_preyear !== 0) {
    d.caseno_preyear = null
  }
  if (typeof d.casenotime_preyear !== 'string' || d.casenotime_preyear.length === 0) {
    d.casenotime_preyear = null
  }
}

export function initFormData (data, result) {
  const keys = ['caseno_preyear', 'casenotime_preyear']
  keys.forEach(k => {
    if (data[k] !== undefined && data[k] !== null) {
      result[k] = data[k]
    }
  })
}

function Main () {
  return (
    <div>
      <div className={style.title}>办案信息</div>
      <Row gutter={24}>
        <Col span={8}>
          <Item label='每年承办案件数量' name='caseno_preyear'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label='每年办案时间' name='casenotime_preyear'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
      </Row>
    </div>
  )
}

export default Main
