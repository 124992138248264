// 缴费管理
import React from 'react'
import LazyLoad from '../../LazyLoad'

const PayManage = (props) => <LazyLoad loadFn={() => import('../../../pages/payManage/PayManage')} {...props} />
const FapiaoApplyList = (props) => <LazyLoad loadFn={() => import('../../../pages/payManage/FapiaoApplyList/Index')} {...props} />
const MyCaseFapiaoMgt = (props) => <LazyLoad loadFn={() => import('../../../pages/payManage/MyCaseFapiaoMgt/Index')} {...props} />
const CaseFaPiaoDetail = (props) => <LazyLoad loadFn={() => import('../../../pages/payManage/CaseFaPiaoDetail/Index')} {...props} />
const AllCaseFapiaoMgt = (props) => <LazyLoad loadFn={() => import('../../../pages/payManage/AllCaseFapiaoMgt/Index')} {...props} />
const ConfirmFee = (props) => <LazyLoad loadFn={() => import('../../../pages/payManage/ConfirmFee/Index')} {...props} />
const Unpay = (props) => <LazyLoad loadFn={() => import('../../../pages/payManage/Unpay/Index')} {...props} />
const PayDiff = (props) => <LazyLoad loadFn={() => import('../../../pages/payManage/PayDiff/Index')} {...props} />
const PayVoucherList = (props) => <LazyLoad loadFn={() => import('../../../pages/payManage/PayVoucherList/Index')} {...props} />
const TuifeiApplyList = (props) => <LazyLoad loadFn={() => import('../../../pages/payManage/TuifeiApplyList/Index')} {...props} />
const BaoChouTuihuiList = (props) => <LazyLoad loadFn={() => import('../../../pages/payManage/BaoChouTuihuiList/Index')} {...props} />

const router = {
  PayManage, // 交费管理
  FapiaoApplyList, // 发票申请审核
  MyCaseFapiaoMgt, // 我的案件发票列表
  CaseFaPiaoDetail, // 案件发票详情
  AllCaseFapiaoMgt, // 全部案件发票
  ConfirmFee, // 自动确认费用
  Unpay, // 未交费
  PayDiff, // 交费金额不一致
  PayVoucherList, // 新中大记账凭证
  TuifeiApplyList, // 退费申请
  BaoChouTuihuiList // 报酬退回
}

export default router
