import * as T from './actionTypes'
import { setUncompleteMessage, setUncompleteMessageNum } from './actions'
import axios from 'axios'
import { message } from 'antd'

const initialState = {
  userInfo: {},
  token: '',
  sendTime: undefined,
  mobile: '',
  uncompleteMessageList: [],
  uncompleteNum: 0,
  menuKeyTileMap: {}
}

// 设置基本信息
function reducer (state = initialState, action) {
  switch (action.type) {
    case T.SET_USERINFO:
      return {
        ...state,
        userInfo: action.data
      }
    case T.SET_TOKEN:
      return {
        ...state,
        token: action.data
      }
    case T.SET_SEND_TIME:
      return {
        ...state,
        sendTime: action.data
      }
    case T.SET_LOGIN_MOBILE:
      return {
        ...state,
        mobile: action.data
      }
    case T.UPDATE_UNCOMPLETE_MESSAGE:
      getUncompleteMessage(state, action.dispatch)
      return state
    case T.SET_UNCOMPLETE_MESSAGE:
      return {
        ...state,
        uncompleteMessageList: action.data
      }
    case T.SET_UNCOMPLETE_MESSAGE_NUM:
      return {
        ...state,
        uncompleteNum: action.data
      }
    case T.SET_MENU_KEY_TITLE:
      return {
        ...state,
        menuKeyTileMap: action.data
      }
    default:
      return state
  }
}

export default reducer

// 更新未读消息列表
function getUncompleteMessage (state, dispatch) {
  if (!state.token) {
    return null
  }
  axios.get('/api/xapc/message', {
    params: {
      current: 1,
      page_size: 10,
      condition: JSON.stringify([
        { k: 'complete_datetime', o: 'null' }
      ]),
      uid: state.userInfo.id
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${state.token}`
    },
    timeout: 1000 * 30
  }).then(res => {
    const data = res.data.data
    if (res.data.status === 1 && state.token) {
      dispatch(setUncompleteMessage(data.list))
      dispatch(setUncompleteMessageNum(data.total))
    } else {
      message.warning(data.message)
    }
  })
}
