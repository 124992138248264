import React from 'react'
import Admin from '../views/Admin.js'
import Login from '../views/Login'
import ArbitratorDetail from '../views/ArbitratorDetail'
import { Redirect } from 'react-router-dom'
import LoginValidate from '../views/LoginValidate/Index.js'

export const RouterConfig = [
  {
    path: '/',
    component: Admin
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/arbitrator/:id',
    component: ArbitratorDetail
  },
  {
    path: '/login-validate',
    component: LoginValidate
  },
  {
    path: '/*',
    render: () => {
      return <Redirect to='/' />
    }
  }

  // {
  //   path: '/apply-for-a-deferment',
  //   component: ApplyForADeferment,
  //   sceneConfig: {
  //     enter: 'from-bottom',
  //     exit: 'to-bottom'
  //   }
  // },
]
