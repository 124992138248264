import React, { useEffect, useState } from 'react'
import style from './Index.module.less'
import { Form, Row, Col, Tree } from 'antd'
import Text from '@/components/Text'
import { awaitWrap } from '@/assets/js/tool'
import { getTreeRequest } from './ajax'
import ProfessionalTags from '@/assets/js/professionalTags'
import { DownOutlined } from '@ant-design/icons'

const { Item } = Form

async function initTree (setProfessionalTags) {
  const [e, d] = await awaitWrap(getTreeRequest())
  if (e === null && d !== null) {
    setProfessionalTags(new ProfessionalTags(d))
  }
}

export function formatUpdateData (d) {

}

export function initFormData (data, result) {
  if (typeof data.professional_tag === 'string' && data.professional_tag.length > 0) {
    result.professional_tag = data.professional_tag
  }
}

function Main () {
  const [professionalTags, setProfessionalTags] = useState(null)
  useEffect(() => {
    initTree(setProfessionalTags)
  }, [])
  return (
    <div>
      <div className={style.title}>擅长专业</div>
      <Row gutter={24}>
        <Col span={24}>
          <Item label=' ' colon={false} name='professional_tag'>
            <Text format={v => {
              if (!v || !professionalTags) {
                return '无'
              }
              professionalTags.initIdsToTreeList(v)
              return <Tree treeData={professionalTags.currentList} defaultExpandAll showLine={{ showLeafIcon: false }} switcherIcon={<DownOutlined />} />
            }}
            />
          </Item>
        </Col>
      </Row>
    </div>
  )
}

export default Main
