import React from 'react'
import style from './Index.module.less'
import { Form, Row, Col } from 'antd'
import Text from '@/components/Text'

const { Item } = Form

export function formatUpdateData (d) {
  return d
}

export function initFormData (data, result) {
  const keys = ['structure_tel', 'tel', 'fax', 'mobile', 'wechat', 'email', 'structure_address', 'other_address', 'first_address']
  keys.forEach(k => {
    if (data[k] !== undefined && data[k] !== null) {
      result[k] = data[k]
    }
  })
}

function Main () {
  return (
    <div>
      <div className={style.title}>联系方式</div>
      <Row gutter={24}>
        <Col span={8}>
          <Item label='手机号码' name='mobile' labelCol={{ flex: '70px' }} labelAlign='left'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label='单位电话' name='structure_tel' labelCol={{ flex: '100px' }} labelAlign='left'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label='家庭电话' name='tel' labelCol={{ flex: '70px' }} labelAlign='left'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label='传真号码' name='fax' labelCol={{ flex: '70px' }} labelAlign='left'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label='电子邮箱' name='email' labelCol={{ flex: '100px' }} labelAlign='left'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label='单位地址' name='structure_address' labelCol={{ flex: '70px' }} labelAlign='left'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label='其他地址' name='other_address' labelCol={{ flex: '70px' }} labelAlign='left'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label='首选通讯地址' name='first_address' labelCol={{ flex: '100px' }} labelAlign='left'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
      </Row>
    </div>
  )
}

export default Main
