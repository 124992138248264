import { Modal, message } from 'antd'
import React, { useEffect, useRef } from 'react'
import style from './Index.module.less'
import { awaitWrap } from '@/assets/js/tool'
import { sendSMS } from './ajax'
import { useDispatch } from 'react-redux'
import { setLoginMobile, setSendTime } from '@/redux/actions'
import moment from 'moment'

async function sendHandle (mobile, arr) {
  const h = message.loading('校验中', 120)
  const [e] = await awaitWrap(sendSMS(mobile, arr))
  h()
  if (e !== null) {
    message.error(e.message)
  }
  return e === null
}

function Main ({ visible, hide, mobile, callback }) {
  const ref = useRef()
  const dispatch = useDispatch()
  useEffect(() => {
    if (visible && ref.current) {
      const e = document.createElement('div')
      e.setAttribute('id', 'captcha')
      ref.current.appendChild(e)
      window.sliderCaptcha({
        id: 'captcha',
        width: 280,
        height: 150,
        offset: 8,
        loadingText: '正在加载中...',
        failedText: '再试一次',
        barText: '向右滑动填充拼图',
        repeatIcon: 'fa fa-redo',
        remoteUrl: '/api/common/smsCaptcha',
        setSrc: function () {
          return `/slidercaptcha/images/Pic${Math.floor(Math.random() * 4)}.jpg`
        },
        verify: async function (arr, spliced) {
          if (!spliced) {
            throw new Error('验证失败')
          }
          const res = await sendHandle(mobile, arr)
          if (res) {
            message.success('短信发送成功')
            callback()
            dispatch(setSendTime(moment().valueOf()))
            dispatch(setLoginMobile(mobile))
          } else {
            throw new Error('验证失败')
          }
        }
      })
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref && ref.current && ref.current.removeChild(e)
      }
    }
  }, [visible, ref, mobile, callback, dispatch])
  return (
    <Modal title='安全验证' visible={visible} onCancel={hide} footer={null} forceRender>
      <div ref={ref} className={style.container} />
    </Modal>
  )
}

export default Main
