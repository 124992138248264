import React from 'react'
import style from './Index.module.less'
import { Empty, Form } from 'antd'
import Text from '@/components/Text'
import { formatDate } from '@/assets/js/tool'

const { Item, List } = Form

export function formatUpdateData (d) {
}

export function initFormData (data, result) {
  result.structure_log = []
  if (typeof data.structure_log === 'string') {
    try {
      result.structure_log = JSON.parse(data.structure_log)
    } catch (e) {
      result.structure_log = []
    }
  }
  if (data.structure_log instanceof Array) {
    result.structure_log = data.structure_log.map(li => {
      return {
        ...li
      }
    })
  }
}

function Main () {
  return (
    <div className={style.container}>
      <div className={style.title}>工作经历</div>
      <List name='structure_log'>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <div className={style.line} key={key}>
                  <span className={style.time}>
                    <Item name={[name, 'begin_datetime']} noStyle {...restField}>
                      <Text format={v => formatDate(v, 'YYYY.MM')} />
                    </Item>
                    <span>～</span>
                    <Item name={[name, 'end_datetime']} noStyle {...restField}>
                      <Text format={v => v ? formatDate(v, 'YYYY.MM') : '至今'} />
                    </Item>
                  </span>
                  <span className={style.cell}>
                    <Item name={[name, 'structure']} noStyle {...restField}>
                      <Text format={v => v || <span className={style.no}>无</span>} />
                    </Item>
                  </span>
                  <span className={style.cell}>
                    <Item name={[name, 'structure_post']} noStyle {...restField}>
                      <Text format={v => v || <span className={style.no}>无</span>} />
                    </Item>
                  </span>
                  <span className={style.cell}>
                    <Item name={[name, 'structure_type']} noStyle {...restField}>
                      <Text format={v => v || <span className={style.no}>无</span>} />
                    </Item>
                  </span>
                  <span className={style.cell}>
                    <Item name={[name, 'occupation']} noStyle {...restField}>
                      <Text format={v => v || <span className={style.no}>无</span>} />
                    </Item>
                  </span>
                  <span className={style.cell}>
                    <Item name={[name, 'ismain']} noStyle {...restField}>
                      <Text format={v => v === 1 ? <span className={style.main}>(目前)</span> : ''} />
                    </Item>
                  </span>
                </div>
              )
            })}
            {
              fields.length === 0 && (
                <div>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )
            }
          </>
        )}
      </List>
    </div>
  )
}

export default Main
