import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Space } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import style from './LazyLoad.module.less'

class Empty extends Component {
  render () {
    return <div />
  }
}

export default class LazyLoad extends Component {
  state = {
    model: null,
    component: null,
    status: false,
    loaded: false
  }

  initModel () {
    this.setState({
      model: new Promise((resolve, reject) => {
        this.props.loadFn()
          .then((...p) => {
            this.setState({
              status: true,
              loaded: true
            })
            resolve(...p)
          })
          .catch(() => {
            this.setState({
              status: false,
              loaded: true
            })
            resolve(<Empty />)
          })
      })
    }, () => {
      if (this.state.model) {
        this.setState({
          component: React.lazy(() => this.state.model)
        })
      }
    })
  }

  renderLoading () {
    return (
      <div className={style['loading-box']}>
        <LoadingOutlined />
      </div>
    )
  }

  componentDidMount () {
    this.initModel()
  }

  render () {
    if (!this.state.model) {
      return <div />
    }
    if (this.state.loaded && !this.state.status) {
      return (
        <div className={style['error-box']}>
          <div className={style['error-message']}>
            <Space>
              <span>加载失败</span>
              <Button type='primary' ghost onClick={() => this.initModel()}>重新加载</Button>
              <Button type='primary' onClick={() => window.location.reload()}>刷新页面</Button>
            </Space>
          </div>
        </div>
      )
    }
    const Model = this.state.component
    if (Model) {
      return (
        <React.Suspense fallback={this.renderLoading()}>
          <Model {...this.props} />
        </React.Suspense>
      )
    }
    return <div />
  }
}

LazyLoad.propTypes = {
  loadFn: PropTypes.func,
  close: PropTypes.func,
  toFullInfo: PropTypes.func,
  caseDetail: PropTypes.object
}

LazyLoad.defaultProps = {
  loadFn: () => Promise.resolve(<div />),
  close: () => {},
  toFullInfo: () => {},
  caseDetail: {}
}
