// 设置基本信息
export const SET_USERINFO = 'SET_USERINFO' // 设置用户信息
export const SET_TOKEN = 'SET_TOKEN' // 设置token
export const SET_SEND_TIME = 'SET_SEND_TIME' // 设置发送时间
export const SET_LOGIN_MOBILE = 'SET_LOGIN_MOBILE' // 设置已发送短信的手机号
export const UPDATE_UNCOMPLETE_MESSAGE = 'UPDATE_UNCOMPLETE_MESSAGE' // 更新未读消息列表
export const SET_UNCOMPLETE_MESSAGE = 'SET_UNCOMPLETE_MESSAGE' // 设置未读消息列表
export const SET_UNCOMPLETE_MESSAGE_NUM = 'SET_UNCOMPLETE_MESSAGE_NUM' // 设置未读消息数量
export const SET_MENU_KEY_TITLE = 'SET_MENU_KEY_TITLE' // 设置菜单key和title映射

// tab页面相关
export const ADD_WINDOW = 'ADD_WINDOW' // 打开一个窗口
export const DEL_WINDOW = 'DEL_WINDOW' // 关闭一个窗口
export const MODIFY_WINDOW_META = 'MODIFY_WINDOW_META' // 修改窗口元数据
export const SET_ACTIVE_WINDOW_ID = 'SET_ACTIVE_WINDOW_ID' // 设置当前页面id
export const INIT_MENU_LIST = 'INIT_MENU_LIST' // 初始化菜单
export const MODIFY_WINDOW_ID = 'MODIFY_WINDOW_ID' // 修改窗口id
export const CLEARWINDOW = 'CLEARWINDOW' // 清空接口

// 全局事件相关
export const ADD_SCROLL_EVENT = 'ADD_SCROLL_EVENT' // 添加滚动事件
export const DEL_SCROLL_EVENT = 'DEL_SCROLL_EVENT' // 移除滚动事件
export const CLEAR_SCROLL_EVENT = 'CLEAR_SCROLL_EVENT' // 清空滚动事件

export const ADD_RESIZE_EVENT = 'ADD_RESIZE_EVENT' // 添加窗口改变事件
export const DEL_RESIZE_EVENT = 'DEL_RESIZE_EVENT' // 移除窗口改变事件
export const CLEAR_RESIZE_EVENT = 'CLEAR_RESIZE_EVENT' // 清空窗口改变事件
