// 办案管理
import React from 'react'
import LazyLoad from '../../LazyLoad'

const BusinessReceive = (props) => <LazyLoad loadFn={() => import('../../../pages/handlingCaseManage/BusinessReceive')} {...props} /> // 办案人员已接收案件
const BusinessUnreceiveCaseManage = (props) => <LazyLoad loadFn={() => import('../../../pages/handlingCaseManage/BusinessUnreceiveCaseManage')} {...props} /> // 办案人员待接收案件
const ClosedCaseManage = (props) => <LazyLoad loadFn={() => import('../../../pages/handlingCaseManage/ClosedCaseManage')} {...props} /> // 已结案案件

const router = {
  BusinessReceive, // 办案人员已接收案件
  BusinessUnreceiveCaseManage, // 办案人员待接收案件
  ClosedCaseManage // 已结案案件
}

export default router
