import React, { } from 'react'
import { useParams } from 'react-router-dom'
import ArbitratorDetail from '@/pages/arbitratorManage/ArbitratorDetail/Index'
import style from './ArbitratorDetail.module.less'

function Main () {
  const { id } = useParams()
  return (
    <div className={style.container}>
      <ArbitratorDetail meta={{ id, isOut: true }} />
    </div>
  )
}

export default Main
