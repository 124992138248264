// 案件管理
import React from 'react'
import LazyLoad from '../../LazyLoad'

const MyCase = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/MyCaseManage/Index')} {...props} />
const AllCaseManage = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/AllCaseManage/Index')} {...props} />
const DesignatedSecretary = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/DesignatedSecretary')} {...props} />
const SimpleSearch = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/SimpleSearch')} {...props} />
const ClaimSimpleSearch = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/ClaimSimpleSearch/Index')} {...props} />
const DeliverSimpleSearch = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/DeliverSimpleSearch/Index')} {...props} />
const RoomBookList = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/RoomBookList')} {...props} />
const RoomBookDetail = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/RoomBookDetail')} {...props} />
const FiledCaseManage = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/FiledCaseManage')} {...props} />
const VideoMeetingList = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/VideoMeetingList')} {...props} />
const VideoMeetingDetail = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/VideoMeetingDetail')} {...props} />
const LiveList = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/LiveList/Index')} {...props} />
const LiveDetail = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/LiveDetail/Index')} {...props} />
const AllWarningCase = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/AllWarningCase')} {...props} />
const MyWarningCase = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/MyWarningCase')} {...props} />
const SeriesCaseManage = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/SeriesCaseManage')} {...props} />
const SeriesCaseDetail = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/SeriesCaseDetail')} {...props} />
const SeriesCaseList = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/SeriesCaseList')} {...props} />
const ExtendTimeManage = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/ExtendTimeManage/Index')} {...props} />
const MyExtendTimeManage = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/MyExtendTimeManage/Index')} {...props} />
const EFiles = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/EFiles')} {...props} />
const EFileDetail = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/EFileDetail/Index')} {...props} />
const DocSealList = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/DocSealList/Index')} {...props} />
const CloseFeeTable = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/CloseFeeTable/Index')} {...props} />
const CaseManageHs = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/CaseManageHs/Index')} {...props} />
const MyCaseExchange = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/MyCaseExchange/Index')} {...props} />
const CaseExchangeDetail = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/CaseExchangeDetail/Index')} {...props} />
const PartyEffectiveDocumentApply = (props) => <LazyLoad loadFn={() => import('@/pages/caseManage/PartyEffectiveDocumentApply/Index')} {...props} />
const router = {
  MyCase, // 我的案件
  AllCaseManage, // 全委案件
  CaseManageHs, // 海丝案件
  DesignatedSecretary, // 重新指定秘书
  SimpleSearch, // 简单查询
  RoomBookList, // 庭室使用情况列表
  RoomBookDetail, // 庭室使用情况编辑页
  FiledCaseManage, // 归档管理
  VideoMeetingList, // 视频庭审列表
  VideoMeetingDetail, // 视频庭审详情
  AllWarningCase, // 案件预警
  MyWarningCase, // 我的案件预警
  SeriesCaseManage, // 系列案管理
  SeriesCaseDetail, // 系列案详情
  SeriesCaseList, // 系列案列表
  ExtendTimeManage, // 延长审限
  MyExtendTimeManage, // 我的延长审限
  EFiles, // 电子卷宗
  EFileDetail, // 电子卷宗详情
  DocSealList, // 盖章呈批列表
  CloseFeeTable, // 结案收支表
  LiveList, // 培训列表
  LiveDetail, // 培训详情
  MyCaseExchange, // 仲裁员交互
  PartyEffectiveDocumentApply, // 生效证明申请
  CaseExchangeDetail, // 案件交互详情
  ClaimSimpleSearch, // 请求简单查询
  DeliverSimpleSearch // 送达简单查询
}

export default router
