import React from 'react'
import axios from 'axios'
import { message } from 'antd'
import { setToken, clearWindow, addWindow, delWindow } from '../redux/actions'
// axios.defaults.timeout = 1000 * 30;
// axios.defaults.headers['Content-Type'] = 'application/json';

/**
 * 请求方法
 * @param {*} type 请求类型
 */
function request (type) {
  return function (url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      const { token, userInfo = {} } = this.props
      const instance = axios.create({
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 1000 * 300
      })
      instance.defaults.headers.Authorization = `Bearer ${token}`
      const that = this
      const data = {
        uid: userInfo.id
      // login_realname: userInfo.realname,
      // login_mobile: userInfo.mobile,
      // login_group_id: userInfo.group_id
      }
      if (type === 'post' || type === 'put') {
        if (params instanceof FormData) {
          config = { ...config, params: { ...config.params, uid: userInfo.id } }
        } else {
          params = Object.assign(params, data)
        }
      } else {
        if (!params.params) {
          params.params = {}
        }
        params.params = Object.assign(params.params, data)
      }
      instance[type](url, params, config)
        .then(res => {
          resolve(res.data)
        })
        .catch(e => {
          const { status, data } = e.response || { status: 504 }
          if (status === 401 || (status === 500 && data.message === '登录超时')) {
            // console.log(status, `token: ${token}`, `new token:${this.props.token}`)
            if (token !== this.props.token || !this.props.token) {
              return reject(e)
            }
            that.props.setToken(undefined)
            message.error({
              content: token ? '登录超时' : '请先登录',
              duration: 2,
              key: 'timeout'
            })
            setTimeout(() => {
              if (that.props.history) {
                that.props.clearWindow()
                that.props.history.push('/login')
              } else {
                location.reload()
              }
            }, 2000)
          } else if (status === 504) {
            message.error({
              content: '网络异常',
              duration: 2,
              key: 'neterror'
            })
          } else if (typeof data.message === 'string') {
            message.error(data.message, 2).then(null, null)
          } else if (status >= 400 && status < 500) {
            message.error({
              content: '系统网络异常，请联系网络管理员',
              duration: 2,
              key: 'neterror'
            }).then(null, null)
          } else {
            message.error('服务器异常，请联系管理员', 2).then(null, null)
          }
          reject(e)
        })
    })
  }
}
export default class Component extends React.Component {
  $get = request('get')
  $post = request('post')
  $put = request('put')
  $delete = request('delete')
  state = {
    _fileList: [], // 已有文书列表
    _fileListLoaded: false,
    _fileCreating: false,
    _printing: false // 打印中
  }

  // 获取案件文书列表
  $getFileList (caseid) {
    this.setState({
      _fileListLoaded: false
    })
    return new Promise((resolve, reject) => {
      this.$get(`/api/xapc/case/file/${caseid}`, {
        params: {
          current: 1,
          page_size: 1000
        }
      })
        .then(res => {
          if (res.status === 1) {
            const data = {
              _fileList: res.data.list,
              _fileListLoaded: true
            }
            this.setState(data, () => {
              resolve(data)
            })
          } else {
            reject(new Error(res.message))
          }
        })
    })
  }

  // 生成指定文书
  $createDoc (caseid, fileNames = [], bizid = '', notice = '') {
    const hide = message.loading(notice || '文书生成中', 120)
    this.setState({
      _fileCreating: true
    })
    const data = {
      caseid,
      filename: fileNames
    }
    if (bizid) {
      data.bizid = bizid
    }
    return new Promise((resolve, reject) => {
      this.$post('/api/xapc/case/file', data).then(res => {
        hide()
        this.setState({
          _fileCreating: false
        })
        if (res.status === 1) {
          this.$getFileList(caseid)
            .then(() => resolve(res.data))
        } else {
          throw new Error(res.message)
        }
        return res.data
      }).catch(e => {
        hide()
        this.setState({
          _fileCreating: false
        })
        message.warning(e)
      })
    })
  }

  /**
   * 打开菜单
   */
  $openPage ({ id = '', title = '', componentName = '', closable = true, meta = {}, isHome = false, forceRefresh = false }) {
    this.props.addWindow({ id, title, componentName, closable, meta, isHome, forceRefresh })
  }

  // 查看pdf文书
  $readerPdf (filename, id, caseid, readonly = false) {
    this.$openPage({ id: `${filename}-${id}`, title: `${filename}`, componentName: 'PdfReader', meta: { caseid: caseid, fileid: id, readonly } })
  }

  // 查看指定名称的文书
  $readerFileByName (filename, caseid) {
    const list = this.state._fileList
    let id = ''
    for (let i = 0; i < list.length; i++) {
      if (list[i].title.indexOf(filename) !== -1) {
        id = list[i].id
        this.$readerPdf(list[i].title, id, caseid)
        break
      }
    }
    if (!id) {
      message.warning('文书不存在！')
    }
  }

  // 查看文书id的文书
  $readerFileById (fileid, caseid) {
    const list = this.state._fileList || []
    let index = -1
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === fileid) {
        index = i
        this.$readerPdf(list[i].title, fileid, caseid)
        break
      }
    }
    if (index === -1) {
      message.warning('文书不存在！')
    }
  }

  // 打印文档
  $print (filepath) {
    this.setState({
      _printing: true
    })
    const oldIframe = document.getElementById('print')
    if (oldIframe) {
      document.body.removeChild(oldIframe)
    }
    const hide = message.loading('文件加载中，准备打印', 120)
    const iframe = document.createElement('iframe')
    iframe.setAttribute('id', 'print')
    iframe.setAttribute('style', 'width: 0; height: 0; position: absolute; top: -500px; left: -500px;')
    iframe.setAttribute('src', filepath)
    iframe.onload = () => {
      hide()
      this.setState({
        _printing: false
      })
      iframe.contentWindow.print()
    }
    document.body.appendChild(iframe)
  }

  // // 打印文档
  // $print (filepath) {
  //   this.setState({
  //     _printing: true
  //   })
  //   const hide = message.loading('文件加载中，准备打印', 120)
  //   const that = this
  //   const iframe = document.createElement('iframe')
  //   iframe.setAttribute('style', 'width: 0; height: 0; position: absolute; top: -500px; left: -500px;')
  //   iframe.setAttribute('src', `/viewer.html?file=${filepath}`)
  //   iframe.onload = () => {
  //     iframe.contentWindow.postMessage('123', '*')
  //     const winObj = iframe.contentWindow
  //     const doc = winObj.document
  //     const printContainer = doc.querySelector('#printContainer')
  //     function isReady () {
  //       if (!winObj.PDFViewerApplication.pdfViewer || !winObj.PDFViewerApplication.pdfViewer.pageViewsReady) {
  //         setTimeout(() => {
  //           isReady()
  //         }, 100)
  //       } else {
  //         winObj.PDFViewerApplication.eventBus.on('afterprint', function () {
  //           that.setState({ _printing: false })
  //           console.log('printed')
  //           delIframe()
  //         })
  //         winObj.PDFViewerApplication.eventBus.dispatch('print')
  //         isFull(winObj.PDFViewerApplication.printService.pagesOverview.length)
  //       }
  //     }
  //     // 判断文件是否加载完毕
  //     function isFull (max) {
  //       const len = printContainer.querySelectorAll('div').length
  //       const percent = Math.round(len / max * 100)
  //       console.log(percent + '%')
  //       if (len !== max) {
  //         setTimeout(() => {
  //           isFull(max)
  //         }, 50)
  //       } else {
  //         hide()
  //         console.log('printing')
  //       }
  //     }
  //     isReady()
  //   }
  //   function delIframe () {
  //     document.body.removeChild(iframe)
  //   }
  //   document.body.appendChild(iframe)
  // }
}

export function mapStateToProps (fn = () => {}) {
  return function (state) {
    const basic = {
      userInfo: state.basic.userInfo,
      token: state.basic.token
    }
    const newState = fn(state)
    return {
      ...basic,
      ...newState
    }
  }
}

export function mapDispatchToProps (fn = () => {}) {
  return function (dispatch) {
    const basic = {
      setToken: (i) => dispatch(setToken(i)),
      clearWindow: (i) => dispatch(clearWindow(i)),
      addWindow: (i) => dispatch(addWindow(i)),
      delWindow: (i) => dispatch(delWindow(i))
    }
    const newState = fn(dispatch)
    return {
      ...basic,
      ...newState
    }
  }
}
