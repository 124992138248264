import React from 'react'
import style from './Index.module.less'
import { Form, Row, Col } from 'antd'
import Text from '@/components/Text'

const { Item } = Form

export function formatUpdateData (d) {

}

export function initFormData (data, result) {
  const keys = ['emergency_contact_structure', 'emergency_contact', 'emergency_contact_mobile']
  keys.forEach(k => {
    if (data[k] !== undefined && data[k] !== null) {
      result[k] = data[k]
    }
  })
}

function Main () {
  return (
    <div>
      <div className={style.title}>紧急联系人</div>
      <Row gutter={24}>
        <Col span={8}>
          <Item label='工作单位' name='emergency_contact_structure'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label='姓名' name='emergency_contact'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
        <Col span={8}>
          <Item label='手机号' name='emergency_contact_mobile'>
            <Text format={v => v || <span className={style.no}>无</span>} />
          </Item>
        </Col>
      </Row>
    </div>
  )
}

export default Main
