import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Text extends Component {
  render () {
    const { value, format, onClick } = this.props
    return (
      <span onClick={() => typeof onClick === 'function' && onClick()}>{format ? format(value) : value}</span>
    )
  }
}

Text.propTypes = {
  format: PropTypes.func
}

Text.defaultProps = {
  format: null
}
