// 仲裁员管理
import React from 'react'
import LazyLoad from '../../LazyLoad'

const ArbitratorList = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorList/Index')} {...props} />
const ArbitratorFee = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorFee/Index')} {...props} />
const ArbitratorMgt = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorManage/Index')} {...props} />
const ArbitratorEdit = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorEdit/Index')} {...props} />
const ArbitratorDetail = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorDetail/Index')} {...props} />
const UnsubmitRemuneration = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/UnsubmitRemuneration/Index')} {...props} />
const MyUnsubmitRemuneration = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/MyUnsubmitRemuneration/Index')} {...props} />
const ArbitratorApplyAudit = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorApplyAudit/Index')} {...props} />
const ArbitratorFeePreview = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorFeePreview/Index')} {...props} />
const ArbitratorFeePay = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorFeePay/Index')} {...props} />
const ApplicationMgt = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ApplicationMgt/Index')} {...props} />
const ArbitratorAccountManage = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorAccountManage/Index')} {...props} />
const XAArbitratorMgt = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/XAArbitratorMgt/Index')} {...props} />
const MessagesSendMgt = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/MessagesSendMgt/Index')} {...props} />
const MessagesSendDetail = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/MessagesSendDetail/Index')} {...props} />
const ArbitratorHearing = (props) => <LazyLoad loadFn={() => import('../../../pages/arbitratorManage/ArbitratorHearing')} {...props} />

const router = {
  ArbitratorList, // 仲裁员列表
  ArbitratorFee, // 仲裁员酬金
  ArbitratorMgt, // 仲裁员管理
  ArbitratorEdit, // 仲裁员编辑
  ArbitratorDetail, // 仲裁员详情
  UnsubmitRemuneration, // 未报报酬
  MyUnsubmitRemuneration, // 我的未报
  ArbitratorApplyAudit, // 仲裁员申请审核
  ArbitratorFeePreview, // 仲裁员酬金推送预览
  ArbitratorFeePay, // 仲裁员酬金推送记录
  ApplicationMgt, // 续聘管理
  ArbitratorAccountManage, // 仲裁员账号管理
  XAArbitratorMgt, // 厦管仲裁员管理
  MessagesSendMgt, // 批量发送信息
  MessagesSendDetail, // 发送消息详情
  ArbitratorHearing // 仲裁员开庭情况
}

export default router
