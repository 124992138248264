import { download } from '@/assets/js/downloadRename'
import { limitStr } from '@/assets/js/tool'
import React from 'react'
import style from './Index.module.less'

function Main ({ value }) {
  const list = value instanceof Array ? value : []
  if (list.length === 0) {
    return <span>无</span>
  }
  return (
    <span className={style.container}>
      {
        list.map((li, i) => {
          const name = `证书${i + 1}`
          return (
            <span key={i} className={style.file}>
              <a rel='noopener noreferrer' title={name} onClick={() => download(li.path, name)}>{limitStr(name, 20)}</a>
            </span>
          )
        })
      }
    </span>
  )
}

export default Main
